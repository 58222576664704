import {
	createRouter,
	createWebHashHistory,
	createWebHistory,
	RouteRecordRaw,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/home-page.vue"),
	},
	{
		path: "/doorphone1",
		name: "Doorphone",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/doorphone-1.vue"),
	},
	{
		path: "/X915",
		name: "X915",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/大門口機/product-X915.vue"
			),
	},
	{
		path: "/R29",
		name: "R29",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/大門口機/product-R29.vue"
			),
	},
	{
		path: "/doorphone2",
		name: "Doorphone2",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/doorphone-2.vue"),
	},
	{
		path: "/R20A",
		name: "R20A",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/小門口機/product-R20A.vue"
			),
	},
	{
		path: "/R20B",
		name: "R20B",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/小門口機/product-R20B.vue"
			),
	},
	// {
	//   path: '/A05S',
	//   name: 'A05S',
	//   component: () => import(/* webpackChunkName: "about" */ '../views/product-A05S.vue')
	// },
	{
		path: "/E12",
		name: "E12",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/小門口機/product-E12.vue"
			),
	},
	{
		path: "/E16",
		name: "E16",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/小門口機/product-E16.vue"
			),
	},
	{
		path: "/doorphone3",
		name: "doorphone3",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/doorphone-3.vue"),
	},
	{
		path: "/X933",
		name: "X933",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/室內機/product-X933.vue"
			),
	},
	{
		path: "/C319",
		name: "C319",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/室內機/product-C319.vue"
			),
	},
	{
		path: "/C313",
		name: "C313",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/室內機/product-C313.vue"
			),
	},
	{
		path: "/IT88",
		name: "IT88",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/室內機/product-IT88.vue"
			),
	},
	{
		path: "/doorphone4",
		name: "doorphone4",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/doorphone-4.vue"),
	},
	{
		path: "/R49G",
		name: "R49G",
		component: () =>
			import(
				/* webpackChunkName: "about" */ "../views/管理機/product-R49G.vue"
			),
	},

	{
		path: "/techdocs",
		name: "techdocs",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/techdocs-page.vue"),
	},
	{
		path: "/contact",
		name: "contact",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/contact-page.vue"),
	},

	{
		path: "/product2",
		name: "product2",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/product-2.vue"),
	},
	{
		path: "/software",
		name: "software",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/software.vue"),
	},
	{
		path: "/software2",
		name: "software2",
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/software2.vue"),
	},
	{
		path: "/hamburger",
		name: "hamburger",
		component: () =>
			import(/* webpackChunkName: "about" */ "../components/hamburgerMenu.vue"),
	},
];

const router = createRouter({
	// history: createWebHashHistory(),
	// history: createWebHistory(location.pathname),
	history: createWebHistory("/"),
	routes,
	scrollBehavior(to, from, savePosition) {
		return { top: 0 };
	},
});

export default router;
