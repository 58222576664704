import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import './assets/myStyle.css'

// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'

// 安裝第三方套件需在tsconfig.json內加入下兩行
// "noImplicitAny": false,
// "allowJs": true,

createApp(App).use(router).use(VueFullPage).mount('#app')
