<template lang="">
	<footer id="footer">
		<div class="footerGroup1">
			<div class="footerItems">
				<div>
					<img :src="require('../assets/media/phone-alt.png')" alt="" />
				</div>
				<div>02-2886 1536</div>
			</div>

			<div class="footerItems">
				<div>
					<img :src="require('../assets/media/envelope-alt.png')" alt="" />
				</div>
				<div>hf54364153@gmail.com</div>
			</div>

			<div class="footerItems">
				<div>
					<img :src="require('../assets/media/building.png')" alt="" />
				</div>
				<div>台北市士林區承德路四段10巷63號1樓</div>
			</div>
		</div>

		<div class="footerGroup2">
			&copy; HoungFu Technology CO.,LTD. All rights reserved
		</div>
	</footer>
</template>
<script setup lang="ts"></script>
